<template>
  <div></div>
</template>

<script>
import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const routerState = () => {
  const router = useRouter()
  const fnSignIn = () => {
    router.replace({ path: '/sign/in', query: { isRoot: 1 } })
  }
  const fnMain = () => {
    router.replace({ path: '/main', query: { isRoot: 1 } })
  }
  return { fnSignIn, fnMain }
}

const userState = () => {
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData'])
  })
  return { ...toRefs(state) }
}

export default {
  name: 'intro-index',
  setup() {
    const { proxy } = getCurrentInstance()
    const { userData } = userState()
    const { fnSignIn, fnMain } = routerState()
    if (proxy.$Util.isEmpty(userData.value)) {
      fnSignIn()
    } else {
      fnMain()
    }
    return {
      ...userState(),
      ...routerState()
    }
  }
}
</script>

<style scoped></style>
